import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import {HashRouter as BrowserRouter} from "react-router-dom"



ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='1016960583449-o9f3lmjg0rn6i3fj4ttld3oi24o3v9kl.apps.googleusercontent.com'>      
    <BrowserRouter>
      <App />
      </BrowserRouter>    
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


