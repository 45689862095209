import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../components/Leaderboard/leaderboard.css'

function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await axios.get(`https://physicalpixels.ikdoeict.be/api/leaderBoard.php`); 
        setLeaderboardData(response.data.users); // Stel de ontvangen gegevens in als de nieuwe staat
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    fetchLeaderboardData();
  }, []);

  return (
    <div className='leaderboard'>
      <h2>Leaderboard</h2>
      <ol>
        {leaderboardData.map((user, index) => (
          <div key={index}>
            <span>{index+1}</span><span>{user.name}</span><span>{user.score} </span>
          </div>
        ))}
      </ol>
    </div>
  );
}

export default Leaderboard;
