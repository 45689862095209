import React, { useEffect, useState } from 'react';
import GoogleLogin from '../components/GoogleLogin/GoogleLogin'

function Login() {
  return (
    <div>
      <GoogleLogin/>
    </div>
  )
}

export default Login
