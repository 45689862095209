import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './GoogleLogin.css'; // Import CSS file
import { FaGoogle } from "react-icons/fa";

const GoogleLogin = () => {
    const [profile, setProfile] = useState(null);

    const setCookieData = (userData) => {
        const cookieData = {
            email: userData.email,
            familyName: userData.family_name,
            givenName: userData.given_name,
            googleId: userData.id,
            imageUrl: userData.picture,
            name: userData.name
        };
    
        const encodedData = encodeURIComponent(JSON.stringify(cookieData));
        setCookie('userData', encodedData, 59 / (24 * 60)); // Store user data in cookie with expiration in 59 minutes
    };
    

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setCookie('accessToken', codeResponse.access_token, 59 / (24 * 60)); // Store access token in cookie with expiration in 59 minutes
            fetchUserProfile(codeResponse, true);
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    
    const fetchUserProfile = (user, refresh) => {
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                const userData = res.data;
                setCookieData(userData); // Store user data in cookies
                axios.post(`https://physicalpixels.ikdoeict.be/api/loginGoogle.php`, {
                    email: userData.email,
                    familyName: userData.family_name,
                    givenName: userData.given_name,
                    googleId: userData.id,
                    imageUrl: userData.picture,
                    name: userData.name
                })
                .then(res => {
                    if (res.data.success) {
                        setProfile(res.data);
                        if(refresh){
                            window.location.reload(); // Refresh the page
                        }
                    } else {
                        console.log(res.data.msg);
                    }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    };
    

    const logOut = () => {
        googleLogout();
        removeCookie('accessToken');
        removeCookie('userData'); // Remove user data cookie
        setProfile(null);
        window.location.reload(); // Reload the page
    };

    useEffect(() => {
        const accessToken = getCookie('accessToken');
        if (accessToken) {
            const codeResponse = { access_token: accessToken };
            fetchUserProfile(codeResponse, false);
        } else {
            setProfile(null);
        }
    }, []);

    // Function to set cookie
    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    // Function to remove cookie
    const removeCookie = (name) => {
        document.cookie = name + '=; Max-Age=-99999999;path=/';  
    }

    // Function to get cookie value
    const getCookie = (name) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return "";
    }

    return (
        <div className='container'>
            {profile ? (
                <div>
                    <img className='profilepicture' src={profile.picture} alt="user image" />
                    <h3>Welcome</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <p>Score: {profile.totalScore}</p>
                    <br />
                    <br />
                    <button onClick={logOut} className='button'>Log out</button>
                </div>
            ) : (
                <div>
                    <h2>Hello!</h2>
                    <h3>Nieuwsgierigheid begint hier. Welkom bij Physical Pixel!</h3>
                    <button onClick={login} className='button'>
                        <span className='button-content'>Login with Google<FaGoogle style={{ backgroundColor: 'transparent' }}/></span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default GoogleLogin;
