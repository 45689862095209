import React, { useEffect, useState } from 'react';
import './About.css';
import DetectionAbout from '../Images/DetectionAbout.png';
import OdiseeLogo from '../Images/OdiseeLogo.png';


function About() {
  return (
    <div>
        <h2>About</h2>
        <div className='content'>
            <div className='contentSection'>
                <h3>Our Mission</h3>
                <p>Deze projectopdracht bestaat erin om post-its uit de fysieke wereld te detecteren en om te zetten naar digitale data die voorgesteld wordt op een website. Als toevoeging zijn er dagelijkse opdrachten om mensen aan te moedigen te interageren met de post-its. Het project kan overal geplaatst worden waar een computer en camera opgesteld kunnen worden.</p>
                <img src={DetectionAbout} alt="postits op muur"/>
            </div>

            <div className='contentSection'>
                <h3>Project Origins</h3>
                <p>Dit project is ons toegewezen door de school Odisee. Dankzij deze opdracht krijgen we de kans om onze kennis in de praktijk te brengen en belangrijke vaardigheden te ontwikkelen. Odisee ondersteunt ons door uitdagende en leerzame projecten aan te bieden die bijdragen aan onze academische en persoonlijke groei. We waarderen de begeleiding en de mogelijkheden die Odisee ons biedt.</p>
                <img src={OdiseeLogo} alt="Odisee Logo"/>
            </div>
        </div>
    </div>
  )
}

export default About
