import React from 'react';
import './Board.css'; // Import CSS file

function Board({postIts}) {

  let maxRow = 0;
  let maxColumn = 0;

  const getCellColor = (rowIndex, columnIndex) => {
    for (const postIt of postIts) {
      const [row, column] = postIt[0].split(',').map(Number);
      if (row === rowIndex && column === columnIndex) {
        //console.log(postIt[1]);
        return postIt[1];
      }
    }
    return 'white';
  };
  
  const findMaxRowAndColumn = (postIts) => {
    for (const postIt of postIts) {
      const [row, column] = postIt[0].split(',').map(Number);
      
      if (row >= maxRow) {
        maxRow = row + 1;
      }
      if (column >= maxColumn) {
        maxColumn = column + 1;
      }
    }
  };

  findMaxRowAndColumn(postIts);



  return (
    <div>
      <table className="board-table">
        <tbody>
          {Array.from({ length: maxRow}, (_, i) => (
            <tr key={`row-${i}`}>
              {Array.from({ length: maxColumn}, (_, j) => (
                <td key={`cell-${i}-${j}`} className="board-cell" style={{backgroundColor : getCellColor(i,j)}}>
                </td>
              ))}

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Board;
