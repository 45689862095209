import './App.css';
import React from 'react';
import GoogleLogin from './components/GoogleLogin/GoogleLogin';
import { Route, Routes } from 'react-router-dom';
import Leaderboard from './pages/Leaderboard';
import Home from './pages/Home';
import Login from './pages/Login';
import Navbar from './components/Navbar/Navbar';
import About from './pages/About'
import Footer from './components/Footer/Footer'

function App() {
    return (
        <>
            <div className='appContainer'>
                <div>
                    <Navbar/>
                    <div>
                        <Routes>
                            <Route path='/' element={<Home/>} />
                            <Route path='/Leaderboard' element={<Leaderboard/>} />
                            <Route path='/About' element={<About/>} />
                            <Route path='/Login' element={<Login/>} />
                        </Routes>
                    </div> 
                </div>  
                <Footer/>  
            </div> 
        </>
    );
}

export default App;