import React from 'react';
import './Challenge.css'; // Import CSS file
import Board from '../Board/Board';

function Challenge({Challenge1, Challenge2, Challenge1Score, Challenge2Score}) {


  return (
    <div className='challengeContent'>
      <div className='challenge'>
        <h2>Challenge 1</h2>
        <h3>{Challenge1Score} punten</h3>
        <Board postIts={Challenge1} />
      </div>
      <div className='challenge'>
        <h2>Challenge 2</h2>
        <h3>{Challenge2Score} punten</h3>
        <Board postIts={Challenge2} />
      </div>
    </div>
  );
};

export default Challenge;
