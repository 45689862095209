import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";



function Navbar() {
  

  let userData = '';
  const [profile, setProfile] = useState(null);

  const isAuthenticated = () => {
    const userId = getCookie('accessToken');
    //console.log(userId)
    return userId !== '';
    
};

// Function to get cookie value
const getCookie = (name) => {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
  return "";
}

const getCookieData = (name) => {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
          const cookieValue = cookie.substring(cookieName.length, cookie.length);
          try {
              return JSON.parse(decodeURIComponent(cookieValue));
          } catch (error) {
              console.error('Error parsing cookie data:', error);
              return null; // Return null if parsing fails
          }
      }
  }
  return null; // Return null if cookie not found
}


  const handleMenuClick = () => {
    const burgerMenus = document.querySelectorAll('.burgerMenu');
    burgerMenus.forEach(menu => {
        menu.classList.toggle('disable');
    });

    document.querySelector('.navBarMobile').classList.toggle('disable')
};

useEffect(() => {
  userData = getCookieData('userData');
  if(isAuthenticated){
    setProfile(userData)
  }else{
  setProfile(null)}
}, []);

  return (
    <header>
      <div className='MainNav'>
        <div>
          <div>
            <Link to='/'>
              <h1>Physical<b>Pixels</b></h1>
            </Link>
          </div>
          <div className='navBarPc'>
            <div>
              <Link to='/'>Home</Link>
            </div>
            <div>
              <Link to='/Leaderboard'>Leaderboard</Link>
            </div>
            <div>
              <Link onClick={handleMenuClick} to='/About'>About</Link>
            </div>

            {isAuthenticated() ? (
                <div className='AccountHeader'>
                    {profile && profile.imageUrl && <Link to="/Login">{profile.givenName}</Link>}
                    {profile && profile.imageUrl && <Link to="/Login"><img className='accountImg' src={profile.imageUrl} /></Link>}
                </div>
              ) : (
                <Link to="/Login">Login</Link>
              )}
            
          </div>
        </div>
        <RxHamburgerMenu className='burgerMenu' onClick={handleMenuClick}/>
        <IoMdClose  className='burgerMenu disable' onClick={handleMenuClick}/>

      </div>
      <div className='navBarMobile disable'>
        <div>
          <Link onClick={handleMenuClick} to='/'>Home</Link>
        </div>
        <div>
          <Link onClick={handleMenuClick} to='/Leaderboard'>Leaderboard</Link>
        </div>
        <div>
          <Link onClick={handleMenuClick} to='/About'>About</Link>
        </div>
        {isAuthenticated() ? (
                <div>
                <Link onClick={handleMenuClick} to='/Login'>Account</Link>
              </div>
              ) : (
                <div>
          <Link onClick={handleMenuClick} to='/Login'>Login</Link>
        </div>
              )}
      </div>
    </header>
  )
}

export default Navbar

