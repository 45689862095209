import './Footer.css'; // Import CSS file
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div className="footer">    
                <div className="row">
                    <ul>
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li>
                            <Link to='/Leaderboard'>Leaderboard</Link>
                        </li>
                        <li>
                            <Link to='/About'>About</Link>
                        </li>
                        <li>
                            <Link to="/Login">Login</Link>
                        </li>
                    </ul>
                </div>
                <div className="row">
                    Copyright © 2024 - All rights reserved
                </div>
            </div>
        </footer>
    );
}

export default Footer;
