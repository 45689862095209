import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Board from '../components/Board/Board';
import Challenge from '../components/Challenge/Challenge';
import { Link } from 'react-router-dom';
import { FaGoogle } from "react-icons/fa";
import Message from '../components/Message/Message';
import './home.css';

function Home() {
  const [postIts, setPostIts] = useState([]);
  const [challenge1, setChallenge1] = useState([]);
  const [challenge2, setChallenge2] = useState([]);
  const [profile, setProfile] = useState(null);
  const [score1, setScore1] = useState([]);
  const [score2, setScore2] = useState([]);
  const [challengesFetched, setChallengesFetched] = useState(false);
  const [erIsGePost, setErIsGePost] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [messages, setMessages] = useState([]);
  const [challengeComplete, setComplete] = useState([])

  let magHet = true;
  let ws;
  let interval;

  const isAuthenticated = () => {
    const userId = getCookie('accessToken');
    return userId !== '';
  };

  const getCookieData = (name) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        const cookieValue = cookie.substring(cookieName.length, cookie.length);
        try {
          return JSON.parse(decodeURIComponent(cookieValue));
        } catch (error) {
          console.error('Error parsing cookie data:', error);
          return null;
        }
      }
    }
    return null;
  };

  const getCookie = (name) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  };

  function pastIn(inputArray, demoArray) {
    if (demoArray.length < inputArray.length || demoArray[0].length < inputArray[0].length) {
      console.log("Verkeerde grootte");
      return false;
    }
    for (let i = 0; i <= demoArray.length - inputArray.length; i++) {
      for (let j = 0; j <= demoArray[0].length - inputArray[0].length; j++) {
        let match = true;
        for (let m = 0; m < inputArray.length; m++) {
          for (let n = 0; n < inputArray[0].length; n++) {
            if (inputArray[m][n].toUpperCase() !== demoArray[i + m][j + n].toUpperCase() && demoArray[i + m][j + n].toUpperCase() !== "WHITE") {
              match = false;
              break;
            }
          }
          if (!match) break;
        }
        if (match) return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const geenPost = () => {
      setErIsGePost(false);
      console.log("tijd is op");
    };

    const resetInterval = () => {
      clearInterval(interval);
      interval = setInterval(geenPost, 600000); // 10 seconden interval
    };

    const fetchPostIts = async () => {
      try {
        setErIsGePost(true);
        resetInterval();
        const response = await axios.get('https://physicalpixels.ikdoeict.be/get');
        setPostIts(response.data);
      } catch (error) {
        console.error('Error fetching post-its:', error);
      }
    };

    ws = new WebSocket('wss://physicalpixels.ikdoeict.be:443/ws');

    ws.onopen = () => {
      console.log('Connected to the WebSocket server');
      fetchPostIts();
    };

    ws.onmessage = (event) => {
      let bericht = JSON.parse(event.data);
      if (bericht.message === "New post-its received") {
        fetchPostIts();
      } else {
        console.log(bericht.message + " heeft een challenge complete");
        setComplete(bericht.message + "");
      }
      resetInterval(); // Reset interval bij elk nieuw bericht
    };

    ws.addEventListener("error", (event) => {
      console.log(event);
    });

    ws.onclose = () => {
      console.log('Disconnected from the WebSocket server');
    };

    interval = setInterval(geenPost, 600000); // 10 seconden interval

    return () => {
      ws.close();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!profile) {
      const userData = getCookieData('userData');
      setProfile(userData);
    }

    const fetchChallenges = async () => {
      if (isAuthenticated() && profile != null) {
        const response = await axios.get(`https://physicalpixels.ikdoeict.be/api/getChallengeId.php?id=${profile.email}`);
        const response2 = await axios.get(`https://physicalpixels.ikdoeict.be/api/getChallenges.php?id=${response.data.challengeOne}`);
        const inputArray = JSON.parse(response2.data.challenge);
        const convertedArray = inputArray.map(item => item);
        const score = JSON.parse(response2.data.score);
        setChallenge1(convertedArray);
        setScore1(score);

        const response3 = await axios.get(`https://physicalpixels.ikdoeict.be/api/getChallenges.php?id=${response.data.challengeTwo}`);
        const inputArray2 = JSON.parse(response3.data.challenge);
        const convertedArray2 = inputArray2.map(item => item);
        const score2 = JSON.parse(response3.data.score);
        setChallenge2(convertedArray2);
        setScore2(score2);

        setChallengesFetched(true);
      }
    };

    fetchChallenges();
  }, [profile, refresh]);

  useEffect(() => {
    const checkChallenges = async () => {
      if (isAuthenticated() && postIts.length > 0 && magHet) {
        if (challenge1.length > 0) {
          let output1 = pastIn(challenge1, postIts);
          if (output1) {
            magHet = false;
            console.log("Cleared 1");
            let email = profile.email;
            await axios.get(`https://physicalpixels.ikdoeict.be/api/challengeCompleted.php?id=${email}&score=${score1}&challenge=1`)
            let payload = { message: profile.name };
            await axios.post('https://physicalpixels.ikdoeict.be/postCompleted', payload)
              .then(magHet = true);
            setRefresh(!refresh);
          }
        }
        if (challenge2.length > 0) {
          let output2 = pastIn(challenge2, postIts);
          if (output2) {
            magHet = false;
            console.log("Cleared 2");
            let email = profile.email;
            await axios.get(`https://physicalpixels.ikdoeict.be/api/challengeCompleted.php?id=${email}&score=${score2}&challenge=2`)
            let payload = { message: profile.name };
            await axios.post('https://physicalpixels.ikdoeict.be/postCompleted', payload)
              .then(magHet = true);
            setRefresh(!refresh);
          }
        }
      }
    };

    if (challengesFetched) {
      checkChallenges();
    }
  }, [postIts]);

  useEffect(() => {
    // Interval to clean up old messages after 30 seconds
    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      setMessages(prevMessages => prevMessages.filter(msg => now - msg.timestamp < 600000));
    }, 1000);

    return () => clearInterval(cleanupInterval);
  }, []);

  useEffect(() => {
    // Interval to simulate receiving new messages
    if(challengeComplete.length > 0){
      const newMessage = {
        id: Date.now(),
        text: `${new Date().toLocaleTimeString()} : ` + challengeComplete +' completed a challenge',
        timestamp: Date.now(),
      };
      setMessages(prevMessages => {
        // Add new message and slice to keep only the last 20 messages
        const updatedMessages = [...prevMessages, newMessage].slice(-7);
        return updatedMessages;
      });
    }
  }, [challengeComplete]);
 
  return (
    <div>
      {isAuthenticated() ? (
        <div>
          <div className='informationBoard'>
            <div className="chat" id='none'>
                {messages.map(msg => (
                  <Message key={msg.id} text={msg.text} />
                ))}
            </div>
            <div className='boardAndFeed'>
            <div className='liveFeed'>
              {erIsGePost ? (
                <h1 className='liveText'>live</h1>
                ) : (
              <h1 className='liveText'>Not live</h1>
              )}
            </div>
              <Board postIts={postIts} />
            </div>
            <div className="chat">
              <h2>LiveFeed</h2>
              <div className='messages'>
                {messages.map(msg => (
                  <Message key={msg.id} text={msg.text} />
                ))}
              </div>
            </div>
          </div>
          <Challenge Challenge1={challenge1} Challenge2={challenge2} Challenge1Score={score1} Challenge2Score={score2} />
        </div>
      ) : (
        <div className='homeHeaderContent'>
          <h2>Welkom bij physicalpixels</h2>
          <h3>Log in om je persoonlijke uitdagingen te zien en aan te gaan.</h3>
          <button className='button'>
            <Link to="/Login">
              <span className='button-content'>Ga naar login<FaGoogle style={{ backgroundColor: 'transparent' }} /></span>
            </Link>
          </button>
          <div className='informationBoard'>
            <div className="chat" id='none'>
                {messages.map(msg => (
                  <Message key={msg.id} text={msg.text} />
                ))}
            </div>
            <div className='boardAndFeed'>
            <div className='liveFeed'>
              {erIsGePost ? (
                <h1 className='liveText'>live</h1>
                ) : (
              <h1 className='liveText'>Not live</h1>
              )}
            </div>
              <Board postIts={postIts} />
            </div>
            <div className="chat">
              <h2>LiveFeed</h2>
              <div className='messages'>
                {messages.map(msg => (
                  <Message key={msg.id} text={msg.text} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
